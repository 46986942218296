@font-face {
  font-family: "radioGrotesk";
  src: url("radio/PPRadioGrotesk-Regular.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}

.floating-window {
  position: absolute;
  width: 500px;
  height: 400px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  z-index: 100;
  background-color: var(--background-menu);
  transform: var(--level-four);
  border: 3px solid var(--border-colour);
  display: flex;
  text-align: center;
  animation: fadeIn 0.5s ease-out forwards;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: auto;
}

.floating-window ::-webkit-scrollbar {
  display: none;
}

.fade-out {
  animation: fadeOut 0.5s ease-out forwards;
}

.floating-window-content {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: var(--font-colour);
  text-decoration: none;
  border: none;
  background-color: transparent;
  font-weight: var(--fw-header);
  font-size: 20px;
}

.disclaimer {
  font-size: 13px;
}

.text-window {
  background: transparent;
  border: none;
  border-bottom: 1px solid var(--border-colour);
  outline: none;
  width: 70%;
  height: "auto";
  overflow-y: "hidden";
  color: var(--font-colour);
  font-family: "radioGrotesk", sans-serif;
}

.ask-button {
  cursor: pointer;
  color: var(--font-colour);
  text-decoration: none;
  border: none;
  background-color: transparent;
  font-weight: var(--fw-header);
  font-size: 15px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (max-width: 700px) {
  .floating-window {
    width: auto;
    top: 50vh;
    left: 0;
    right: 0;
  }
}
