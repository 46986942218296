@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

@font-face {
  font-family: "radioGrotesk";
  src: url("radio/PPRadioGrotesk-Regular.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}

:root {
  --level-one: translateZ(3rem);
  --level-two: translateZ(6rem);
  --level-three: translateZ(9rem);
  --level-four: translateZ(12rem);
  --fw-header: 10;
  --fw-normal: 15;
  --fw-bold: 20;
  --border-spacing: 1em;
  --background-colour: #e2fdff;
  --background-menu: #f0feff;
  --border-colour: #5c6965;
  --font-colour: #000000;
  --subtitle-colour: #333333;
  --line-colour: #333333;
  font-family: "radioGrotesk", sans-serif;
  background-color: #18191a;
}

html {
  background-color: var(--background-colour);
}

/* Hides navbar */
html::-webkit-scrollbar {
  display: none;
}

/* Makes all sizing include padding and margin */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

/* Light and Dark mode */
.sun {
  --background-colour: #e2fdff;
  --background-menu: #f0feff;
  --border-colour: #5c6965;
  --font-colour: #000000;
  --subtitle-colour: #333333;
  --line-colour: #333333;
}

.moon {
  --background-colour: #18191a;
  --background-menu: #242526;
  --border-colour: #e4e6eb;
  --font-colour: #e4e6eb;
  --subtitle-colour: #b6ebef;
  --line-colour: #e4e6eb;
}

body {
  min-height: 100vh;
  background-color: var(--background-colour);
  transition: background-color 1000ms ease-in-out, color 1000ms ease-in-out;
}

.card,
.card.flipped {
  /* Reference for children */
  position: relative;
  /* Set Size */
  max-width: 700px;
  height: 880px;
  /* Centering Card */
  margin-left: auto;
  margin-right: auto;
  /* Animation */
  transition: transform 3s;
  /* Preserve transformation for children */
  transform-style: preserve-3d;
}

.card.flipped {
  /* Animation */
  transform: rotateY(0.5turn);
  /* Preserve transformation for children */
  transform-style: preserve-3d;
}

.card_front,
.card_back {
  position: absolute;
  /* Stretch to fit card div */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  /* Hides back */
  backface-visibility: hidden;
  /* Preserve transformation for children */
  transform-style: preserve-3d;
  background-color: var(--background-menu);
}

.card_front::before,
.card_back::before {
  content: "";
  position: absolute;
  top: var(--border-spacing);
  bottom: var(--border-spacing);
  left: var(--border-spacing);
  right: var(--border-spacing);
  border: 3px solid var(--border-colour);
  transform: var(--level-one);
}

.card_front {
  align-content: center;
  text-align: center;
}

.card_back {
  /* Column spacing */
  grid-template-columns: auto auto;
  padding: 75px;
  transform: rotateY(0.5turn);
  /* Styling */
  line-height: 1.6;
  color: var(--font-colour);
  place-items: center;
}

.logo {
  width: 110px;
  height: auto;
}

.disco_div {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-21%) var(--level-three);
}

.disco_gif {
  position: relative;
  width: 40%;
  height: auto;
  overflow: hidden;
}

.column1,
.column2 {
  transform: var(--level-two);
}

.column1 {
  text-align: left;
  align-items: flex-start;
}

.column2 {
  text-align: right;
  align-items: flex-end;
  margin-top: 25px;
}

.clickable,
.clickable:visited,
.clickable:active {
  cursor: pointer;
  text-decoration: underline;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  line-height: normal;
  color: var(--color-primary);
  font-size: 13px;
  line-height: 1.4;
}

.clickable:hover {
  color: #9bb1ff;
}

.column1 hr {
  align-self: flex-start;
  width: 70%;
  margin-right: auto;
}

.column2 hr {
  align-self: flex-end;
  width: 70%;
  margin-left: auto;
}

.card_title {
  font-size: 3.5rem;
  order: 2;
  color: var(--font-colour);
  transform: var(--level-three);
}

.card_subtitle {
  letter-spacing: 4px;
  font-size: 0.75rem;
  font-weight: var(--fw-bold);
  color: var(--subtitle-colour);
  opacity: 0.7;
  transform: var(--level-two);
}

.card_subtitle_back {
  color: var(--subtitle-colour);
}

.menu__item {
  font-size: 13px;
  line-height: 1.4;
}

.menu__section {
  font-weight: var(--fw-header);
  font-size: 16px;
}

.fadeIn-enter {
  opacity: 0;
}

.fadeIn-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in-out;
}

.fadeIn-exit {
  opacity: 0;
}

.fadeIn-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in-out;
}

@media (max-width: 700px) {
  .card,
  .card.flipped {
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 80vw;
  }

  .card_front,
  .card_back {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .card_front {
    position: relative;
  }

  .card_back {
    height: fit-content;
  }

  .column1,
  .column2 {
    align-items: center;
    text-align: center;
  }

  .column2 {
    margin-top: 1em;
  }

  .column1 hr,
  .column2 hr {
    align-self: center;
    margin-right: auto;
    margin-left: auto;
  }

  .card_subtitle {
    padding-top: 50vh;
  }

  .clickable,
  .clickable:visited,
  .clickable:active,
  .clickable:hover {
    color: var(--font-colour);
  }

  .disco_div {
    top: -13px;
    left: 45%;
    transform: translateX(-50%) var(--level-three);
  }
  .disco_gif {
    width: 100%;
  }
}
