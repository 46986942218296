.logbook {
  position: absolute;
  /* Filling the size of the floating window */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  padding: 1em;
}
